import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import ModelosTable from '../components/ModelosTable';
import ModelosForm from '../components/ModelosForm';
import { Group, Button, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";

function CatalogoModelos({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [modelos, setModelos] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroModelo, setFiltroModelo] = useState('');

    useEffect(() => {
        try {
            const getModelos = async () => {
                dispatch(ShowLoading());
                const allModelos = await fetchModelos();
                setModelos(allModelos);
                dispatch(HideLoading());
            }
            getModelos();
        } catch (error) {
            dispatch(HideLoading());
        }
    }, [dispatch]);

    const fetchModelos = async () => {
        try {
            let { data: modelos, error } = await supabase
                .from('cat_modelos')
                .select(`
                    id_modelo,
                    nombre_modelo
                `)
                .order('id_modelo', { ascending: true });

            if (error) {
                throw error;
            }
            return modelos;
        } catch (error) {
            throw error;
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: modelosFiltrados, error } = await supabase
                .from('cat_modelos')
                .select(`
                    id_modelo,
                    nombre_modelo
                `)
                .ilike('nombre_modelo', `%${filtroModelo.toLowerCase()}%`)
                .order('id_modelo', { ascending: true });

            if (error) {
                throw error;
            }
            setModelos(modelosFiltrados);
        } catch (error) {
            showNotification({
                title: 'Error al filtrar modelos',
                message: error.message,
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };

    const getData = async () => {
        try {
            const allModelos = await fetchModelos();
            setModelos(allModelos);
        } catch (error) {
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };

    const canAddModelo = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_modelos &&
        permisos.catalogos.modulos.cat_modelos.acciones &&
        permisos.catalogos.modulos.cat_modelos.acciones.registrar_modelo === true;

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl">
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel">
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar modelo"
                                value={filtroModelo}
                                onChange={(event) => setFiltroModelo(event.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddModelo && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}
                                >
                                    + Agregar Modelo
                                </Button>
                            )}
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Modelo" : "Editar Modelo"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <ModelosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction}
                    />
                </Modal>
                <div style={{ overflowX: "auto" }}>
                    <ModelosTable
                        modelos={modelos}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>
    );
}

export default CatalogoModelos;
