import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import { Group, Button, Title, Modal, Card, Accordion, Text } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconInfoCircle } from '@tabler/icons-react';
import moment from "moment";
import { IconSearch } from '@tabler/icons-react';
import EquiposTable from '../components/EquiposTable';
import EquiposFiltros from '../components/EquiposFiltros';
import EquiposForm from '../components/EquiposForm';
import EquiposCard from '../components/EquiposCard';



function CatalogoEquipos({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [equipos, setEquipos] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroEquipo, setFiltroEquipo] = useState('');
    const icon = <IconInfoCircle />;
    const [view, setView] = React.useState("table");


    //para Filtros Pagos
    const [filters, setFilters] = React.useState({
        numero_serie: "",
        numero_inventario: "",
        nombre_descripcion: "",
        nombre_modelo: "",
        nombre_marca: "",
        nombre_clasificacion: "",
        nombre_ubicacion: "",

    });


    const fetchEquipos = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('cat_equipos').select(`
                id_equipo,
                id_ubicacion,
                cat_ubicaciones!inner(
                    id_ubicacion,
                    nombre_ubicacion
                    ),
                id_clasificacion,
                cat_clasificaciones!inner(
                    id_clasificacion,
                    nombre_clasificacion
                    ),
                numero_serie,
                numero_inventario,
                nombre_descripcion,
                id_modelo,
                cat_modelos!inner(
                    id_modelo,
                    nombre_modelo
                    ),
                id_marca,
                cat_marcas!inner(
                    id_marca,
                    nombre_marca
                    )
                
                `);

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.nombre_clasificacion) {
                supabaseQuery = supabaseQuery.eq('cat_clasificaciones.nombre_clasificacion', filters.nombre_clasificacion);
            }

            if (filters.numero_serie) {
                supabaseQuery = supabaseQuery.eq('numero_serie', filters.numero_serie);
            }

            if (filters.numero_inventario) {
                supabaseQuery = supabaseQuery.eq('numero_inventario', filters.numero_inventario);
            }

            if (filters.nombre_descripcion) {
                supabaseQuery = supabaseQuery.eq('nombre_descripcion', filters.nombre_descripcion);
            }

            if (filters.nombre_modelo) {
                supabaseQuery = supabaseQuery.eq('cat_modelos.nombre_modelo', filters.nombre_modelo);
            }

            if (filters.nombre_marca) {
                supabaseQuery = supabaseQuery.eq('cat_marcas.nombre_marca', filters.nombre_marca);
            }

            if (filters.nombre_ubicacion) {
                supabaseQuery = supabaseQuery.eq('cat_ubicaciones.nombre_ubicacion', filters.nombre_ubicacion);
            }



            let { data: equipos, error } = await supabaseQuery
                .order('id_equipo', { ascending: false });

            if (error) {
                throw error;
            }


            //console.log("Listado de equipos obtenidas:", equipos); // Agregar este console.log para verificar los contratantes obtenidos
            setEquipos(equipos);
            dispatch(HideLoading());

        } catch (error) {
            //console.error('Error al obtener equipos:', error.message);
            dispatch(HideLoading());

        }
    };

    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            await fetchEquipos();
        } catch (error) {
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddEquipo = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_equipos &&
        permisos.catalogos.modulos.cat_equipos.acciones &&
        permisos.catalogos.modulos.cat_equipos.acciones.registrar_equipo === true;

    return (
        <div>
            <Card className='CardTituloReportes'
                mb={'15px'}
            >
                <Text
                    size="xl"
                    fw={900}

                >Equipos
                </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <EquiposFiltros
                            filters={filters}
                            setFilters={setFilters}
                        />
                        <Group mt={'20px'}>
                            <Button leftSection={<IconSearch size={20} />} color="#005187" onClick={getData}>
                                Buscar Equipos
                            </Button>
                            <Button.Group>
                                <Button
                                    color='#005187'
                                    variant={view === 'cards' ? "filled" : "outline"}
                                    onClick={() => setView("cards")}
                                >
                                    Ver Tarjetas
                                </Button>
                                <Button
                                    color='#005187'
                                    variant={view === 'table' ? "filled" : "outline"}
                                    onClick={() => setView("table")}
                                >
                                    Ver Tabla
                                </Button>
                            </Button.Group>
                            {canAddEquipo && (

                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Equipo
                                </Button>
                            )}


                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card style={{ backgroundColor: view === 'cards' ? '#F0F0F0' : 'white' }}>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Equipo" : "Editar Equipo"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <EquiposForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />


                </Modal>



                <div style={{ overflowX: "auto" }}>

                    {view === 'table' ? (

                        <EquiposTable
                            equipos={equipos}
                            setSelectedTransaction={setSelectedTransaction}
                            setFormMode={setFormMode}
                            setShowForm={setShowForm}
                            getData={getData}
                            user={user}
                            permisos={permisos}
                        />
                    ) : (
                        <EquiposCard
                            equipos={equipos}
                            setSelectedTransaction={setSelectedTransaction}
                            setFormMode={setFormMode}
                            setShowForm={setShowForm}
                            getData={getData}
                            user={user}
                            permisos={permisos}
                        />
                    )}

                </div>
            </Card>
        </div>

    );

}


export default CatalogoEquipos;