import React, { useState } from 'react';
import { Table, Button, Group, Pagination, Badge } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { IconCircleFilled } from '@tabler/icons-react';

function OrdenesTable({ ordenes, setSelectedTransaction, setFormMode, setShowForm, getData, filtroOrden, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const ordenesFiltradas = filtroOrden
        ? ordenes.filter(orden => orden.numero_orden.toLowerCase().includes(filtroOrden.toLowerCase()))
        : ordenes;

    const paginatedTransactions = ordenesFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('ordenes')
                .delete()
                .eq('id_orden', id);

            if (error) {
                throw new Error('No se pudo borrar la Orden');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Orden borrada',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la Orden',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((orden) => {
        let dias_faltantes = null;
        let diasFaltantesColor = null;
        let horas_orden = "N.A.";  // Valor por defecto
        let nombre_estatus = '';


        if (!orden.fecha_fin_orden) {
            // Calcula la diferencia de días de la fecha actual hacia la fecha de inicio
            dias_faltantes = moment(orden.fecha_inicio_orden).diff(moment(), 'days');
            if (dias_faltantes > 15) {
                diasFaltantesColor = 'green';
            } else if (dias_faltantes >= 1 && dias_faltantes <= 14) {
                diasFaltantesColor = 'yellow';
            } else if (dias_faltantes <= 0) {
                diasFaltantesColor = 'red';
            }
        }

        // Calcula la diferencia en horas entre fecha_inicio_orden y fecha_fin_orden
        if (orden.fecha_inicio_orden && orden.fecha_fin_orden && moment(orden.fecha_fin_orden).isSameOrAfter(orden.fecha_inicio_orden)) {
            horas_orden = moment(orden.fecha_fin_orden).diff(moment(orden.fecha_inicio_orden), 'hours');
        }

        return (
            <tr key={orden.id_orden}>
                <td>{orden.id_orden}</td>
                <td>{orden.numero_orden}</td>
                <td>
                    <div>
                        {orden.cat_equipos.nombre_descripcion}
                        <br />
                        <strong>No. Serie: </strong>{orden.cat_equipos.numero_serie}
                        <br />
                        <strong>No. Inv: </strong>{orden.cat_equipos.numero_inventario}
                        <br />
                        <strong>Marca: </strong>{orden.cat_equipos.cat_marcas.nombre_marca}
                        <strong>/ Modelo: </strong>{orden.cat_equipos.cat_modelos.nombre_modelo}
                    </div>
                </td>
                <td>
                    <div>
                        <Badge color={
                            orden.cat_estatus.nombre_estatus === 'Concluído'
                                ? 'green'
                                : orden.cat_estatus.nombre_estatus === 'En Proceso'
                                    ? 'orange'
                                    : orden.cat_estatus.nombre_estatus === 'Programado'
                                        ? 'blue'
                                        : 'yellow'
                        }>
                            {orden.cat_estatus.nombre_estatus}
                        </Badge>
                        <br />
                        <strong>Tipo: </strong>{orden.cat_tipos.nombre_tipo}
                    </div>
                </td>
                <td>
                    {orden.cat_ingenieros.nombre_ingeniero}
                </td>
                <td>
                    <Group spacing="xs">
                        {dias_faltantes !== null ? (
                            dias_faltantes <= -1 ? (
                                "N.A."
                            ) : (
                                <>
                                    <IconCircleFilled size={20} color={diasFaltantesColor} />
                                    {dias_faltantes} días
                                </>
                            )
                        ) : (
                            "N.A."
                        )}
                    </Group>
                </td>
                <td>
                    {moment.utc(orden.fecha_inicio_orden).tz('America/Mexico_City').format('DD/MM/YYYY hh:mm A')}
                </td>
                <td>
                    {orden.fecha_fin_orden
                        ? moment.utc(orden.fecha_fin_orden).tz('America/Mexico_City').format('DD/MM/YYYY hh:mm A')
                        : ""}
                </td>
                <td>
                    {horas_orden !== "N.A." ? `${horas_orden} hrs` : horas_orden}
                </td>

                <td style={{ width: '100px' }}>
                    <Group justify="center">
                        {permisos &&
                            permisos.ordenes &&
                            permisos.ordenes.acciones &&
                            permisos.ordenes.acciones.modificar_orden && (
                                <i
                                    key={`edit-${orden.id_orden}`}
                                    className='ri-pencil-line'
                                    onClick={() => {
                                        setSelectedTransaction(orden);
                                        setFormMode("edit");
                                        setShowForm(true);
                                    }}
                                ></i>
                            )}
                        {permisos &&
                            permisos.ordenes &&
                            permisos.ordenes.acciones &&
                            permisos.ordenes.acciones.eliminar_orden && (
                                <i
                                    key={`delete-${orden.id_orden}`}
                                    className='ri-delete-bin-line'
                                    onClick={() => {
                                        if (window.confirm('¿Estás seguro de eliminar esta Orden?')) {
                                            deleteTransaction(orden.id_orden);
                                        }
                                    }}
                                ></i>
                            )}
                    </Group>
                </td>
            </tr>
        );
    });

    const csvHeaders = [
        'ID',
        'Nombre orden',
    ];

    const csvData = ordenes.map((orden) => [
        orden.id_orden,
        orden.numero_orden,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th style={{ width: '50px' }}>ID</th>
                        <th style={{ width: '50px' }}># Orden</th>
                        <th style={{ width: '200px' }}>Equipo</th>
                        <th style={{ width: '130px' }}>Tipo Manto/ Estatus</th>
                        <th style={{ width: '200px' }}>Ingeniero</th>
                        <th style={{ width: '100px' }}>Días faltantes</th>
                        <th style={{ width: '100px' }}>Fecha Inicio</th>
                        <th style={{ width: '100px' }}>Fecha Fin</th>
                        <th style={{ width: '100px' }}>Duración Orden</th>
                        <th style={{ width: '100px' }}>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(ordenes.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'ordenes.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default OrdenesTable;
