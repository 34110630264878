import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function TiposTable({ tipos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroTipo, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const tiposFiltrados = filtroTipo
        ? tipos.filter(tipo => tipo.nombre_tipo.toLowerCase().includes(filtroTipo.toLowerCase()))
        : tipos;

    const paginatedTipos = tiposFiltrados.slice(startIndex, endIndex);

    const deleteTipo = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_tipos')
                .delete()
                .eq('id_tipo', id);

            if (error) {
                throw new Error('No se pudo borrar el Tipo');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Tipo borrado',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Tipo',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTipos.map((tipo) => (
        <tr key={tipo.id_tipo}>
            <td>{tipo.id_tipo}</td>
            <td>{tipo.nombre_tipo}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tipos &&
                        permisos.catalogos.modulos.cat_tipos.acciones &&
                        permisos.catalogos.modulos.cat_tipos.acciones.modificar_tipo && (
                            <i
                                key={`edit-${tipo.id_tipo}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(tipo);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tipos &&
                        permisos.catalogos.modulos.cat_tipos.acciones &&
                        permisos.catalogos.modulos.cat_tipos.acciones.eliminar_tipo && (
                            <i
                                key={`delete-${tipo.id_tipo}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este Tipo?')) {
                                        deleteTipo(tipo.id_tipo);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre tipo',
    ];

    const csvData = tipos.map((tipo) => [
        tipo.id_tipo,
        tipo.nombre_tipo,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre tipo</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(tipos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                <CsvDownloader
                    filename={'tipos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default TiposTable;
