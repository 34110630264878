import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Button, Group } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";

function ModelosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    // Valores iniciales
    const [modelo, setModelo] = useState({
        nombre_modelo: "",
        id_modelo: "" // Inicializar id_modelo como una cadena vacía
    });

    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setModelo({
                nombre_modelo: transactionData.nombre_modelo,
                id_modelo: transactionData.id_modelo
            });
        }
    }, [formMode, transactionData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModelo(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateModelo = async () => {
        try {
            await supabase
                .from('cat_modelos')
                .update({
                    nombre_modelo: modelo.nombre_modelo
                })
                .eq('id_modelo', modelo.id_modelo);

            showNotification({
                title: 'Modelo actualizado',
                message: 'El Modelo se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar el Modelo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el Modelo.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (formMode === 'add') {
                const { error } = await supabase
                    .from('cat_modelos')
                    .insert({
                        nombre_modelo: modelo.nombre_modelo
                    });

                if (error) {
                    throw error;
                }

                showNotification({
                    title: 'Modelo agregado',
                    message: 'El Modelo ha sido agregado con éxito.',
                    color: 'green',
                });
            } else if (formMode === 'edit') {
                await handleUpdateModelo();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al agregar/actualizar Modelo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el Modelo.',
                color: 'red',
            });
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action="" onSubmit={handleSubmit}>
                <Group>
                    <Stack>
                        <TextInput
                            disabled
                            name="id_modelo"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={modelo.id_modelo}
                        />

                        <TextInput
                            name="nombre_modelo"
                            label="Nombre Modelo"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={modelo.nombre_modelo}
                            placeholder='Nombre Modelo'
                        />
                    </Stack>
                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Modelo" : "Editar Modelo"}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default ModelosForm;
