import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Button, Group } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";

function ClasificacionesForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    // Valores iniciales
    const [clasificacion, setClasificacion] = useState({
        nombre_clasificacion: "",
        id_clasificacion: "" // Inicializar id_clasificacion como una cadena vacía
    });

    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setClasificacion({
                nombre_clasificacion: transactionData.nombre_clasificacion,
                id_clasificacion: transactionData.id_clasificacion
            });
        }
    }, [formMode, transactionData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setClasificacion(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateClasificacion = async () => {
        try {
            await supabase
                .from('cat_clasificaciones')
                .update({
                    nombre_clasificacion: clasificacion.nombre_clasificacion
                })
                .eq('id_clasificacion', clasificacion.id_clasificacion);

            showNotification({
                title: 'Clasificación actualizada',
                message: 'La Clasificación se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar la Clasificación:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la Clasificación.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (formMode === 'add') {
                const { error } = await supabase
                    .from('cat_clasificaciones')
                    .insert({
                        nombre_clasificacion: clasificacion.nombre_clasificacion
                    });

                if (error) {
                    throw error;
                }

                showNotification({
                    title: 'Clasificación agregada',
                    message: 'La Clasificación ha sido agregada con éxito.',
                    color: 'green',
                });
            } else if (formMode === 'edit') {
                await handleUpdateClasificacion();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al agregar/actualizar Clasificación:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la Clasificación.',
                color: 'red',
            });
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action="" onSubmit={handleSubmit}>
                <Group>
                    <Stack>
                        <TextInput
                            disabled
                            name="id_clasificacion"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={clasificacion.id_clasificacion}
                        />

                        <TextInput
                            name="nombre_clasificacion"
                            label="Nombre Clasificación"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={clasificacion.nombre_clasificacion}
                            placeholder='Nombre Clasificación'
                        />
                    </Stack>
                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Clasificación" : "Editar Clasificación"}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default ClasificacionesForm;
