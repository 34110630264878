import React, { useEffect, useState } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { IconFileTypePdf, IconPhoto, IconDownload, IconEye, IconTrash, IconEraser } from '@tabler/icons-react';

import { Stack, TextInput, NativeSelect, Group, Button, Textarea, Text, Divider, Modal } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { DateTimePicker } from '@mantine/dates';
import moment from 'moment-timezone';
import FileViewer from 'react-file-viewer';


function OrdenesForm({
    formMode,
    setShowForm,
    transactionData,
    getData,

}) {
    const dispatch = useDispatch();
    const [diasFaltantes, setDiasFaltantes] = useState(null); //para calcular los dias faltantes y especificar el id_estatus

    const [user, setUser] = useState({
        id_orden: "",
        numero_orden: "",
        descripcion_problema: "",
        descripcion_ejecucion: "",
        fecha_inicio_orden: null,
        fecha_fin_orden: null,
        id_equipo: "",
        id_estatus: "",
        id_tipo: "",
        id_ingeniero: "",
    });

    const [equipoDetails, setEquipoDetails] = useState({
        nombre_descripcion: "",
        numero_serie: "",
        numero_inventario: "",
        nombre_marca: "",
        nombre_modelo: "",
        nombre_ubicacion: "",
    });

    // variables para Archivos adjuntos

    // para ver el archivo sin descargar
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [existingFiles, setExistingFiles] = useState([]);

    // para ver archivo
    const openFileModal = (file) => {
        setSelectedFile(file);
        setModalOpen(true);
    };

    // para descargar archivo
    const DownloadFile = (file) => {
        const link = document.createElement('a');
        link.href = file.archivo_url;
        link.target = '_blank'; // Abre el archivo en una nueva pestaña
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const FilePreview = ({ file, onView, onDelete, onDownload }) => (
        <Group key={file.archivo_url} position="apart" nowrap>
            <Text truncate style={{ maxWidth: '60%' }}>{file.nombre_archivo}</Text>
            <Group spacing="xs">
                <Button variant="filled" color="blue" onClick={() => onView(file)}>
                    <IconEye size={16} />
                </Button>
                <Button variant="filled" color="green" onClick={() => onDownload(file)}>
                    <IconDownload size={16} />
                </Button>
                <Button variant="filled" color="red" onClick={() => onDelete(file.archivo_url)}>
                    <IconTrash size={16} />
                </Button>
            </Group>
        </Group>
    );

    // para que se actualice el id_estatus y nombre_estatus en automático
    const calculateEstatus = (data2) => {
        let id_estatus = '';
        let dias_faltantes = null;

        if (data2.fecha_fin_orden) {
            // Si hay fecha de fin, el estatus es 'Concluído'
            id_estatus = '2'; // Concluído
        } else {
            // Si no hay fecha de fin, calcular basado en fecha de inicio
            if (data2.fecha_inicio_orden) {
                dias_faltantes = moment(data2.fecha_inicio_orden).diff(moment(), 'days');

                if (dias_faltantes <= 0) {
                    id_estatus = '3'; // En Proceso
                } else if (dias_faltantes > 0) {
                    id_estatus = '4'; // Programado
                }
            } else {
                // Si no hay fecha de inicio, puedes asignar un estatus predeterminado
                id_estatus = ''; // O asignar un valor predeterminado si aplica
            }
        }

        return { id_estatus, dias_faltantes };
    };


    useEffect(() => {
        if (formMode === 'edit' && transactionData) {
            const { id_estatus, dias_faltantes } = calculateEstatus(transactionData);

            // Actualiza el estado del formulario
            setSelectedEstatus(id_estatus);
            setDiasFaltantes(dias_faltantes);

            // Imprimir el id_estatus y otros valores para depuración
            console.log('Selected Estatus ID:', id_estatus);
            console.log('Dias Faltantes:', dias_faltantes);
        } else if (formMode === 'add') {
            // Modo adición no requiere cálculo de estatus
            setSelectedEstatus('');
            setDiasFaltantes(null);

            // Imprimir el estado en modo adición
            console.log('Form Mode Add: Estatus ID no calculado');
        }
    }, [formMode, transactionData]);





    // Handler to clear the date
    const handleClearDate = () => {
        handleChange({ target: { name: 'fecha_fin_orden', value: null } });
    };

    useEffect(() => {
        if (!user.id_orden) {
            setUser({
                id_orden: "",
                numero_orden: "",
                descripcion_problema: "",
                descripcion_ejecucion: "",
                fecha_inicio_orden: null,
                fecha_fin_orden: null,
                id_equipo: "",
                id_estatus: "",
                id_tipo: "",
                id_ingeniero: "",
            });
            setSelectedEstatus(""); // Asegúrate de resetear también el estatus seleccionado

        }
    }, [user.id_orden]);

    useEffect(() => {
        if (formMode === 'add') {
            // Función para obtener el próximo número de orden
            const fetchNextOrderNumber = async () => {
                try {
                    const { data, error } = await supabase
                        .from('ordenes')
                        .select('id_orden')
                        .order('id_orden', { ascending: false })
                        .limit(1);

                    if (error) throw error;

                    if (data.length > 0) {
                        const lastOrderId = data[0].id_orden;
                        setUser(prevUser => ({
                            ...prevUser,
                            numero_orden: lastOrderId ? (lastOrderId + 1).toString() : '1'
                        }));
                    } else {
                        setUser(prevUser => ({
                            ...prevUser,
                            numero_orden: '1'
                        }));
                    }
                } catch (error) {
                    console.error('Error al obtener el próximo número de orden:', error.message);
                }
            };

            fetchNextOrderNumber();
        }
    }, [formMode]);

    const [equipos, setEquipos] = useState([]);
    const [selectedEquipo, setSelectedEquipo] = useState("");

    const [estatus, setEstatus] = useState([]);
    const [selectedEstatus, setSelectedEstatus] = useState("");

    const [tipos, setTipos] = useState([]);
    const [selectedTipo, setSelectedTipo] = useState("");

    const [ingenieros, setIngenieros] = useState([]);
    const [selectedIngeniero, setSelectedIngeniero] = useState("");


    useEffect(() => {
        const fetchEquipos = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_equipos')
                    .select('id_equipo, nombre_descripcion');

                if (error) throw error;

                // Eliminar duplicados
                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_descripcion)))
                    .map(nombre_descripcion => ({
                        label: nombre_descripcion,
                        value: data.find(item => item.nombre_descripcion === nombre_descripcion).id_equipo
                    }));

                setEquipos(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener los equipos:', error.message);
            }
        };



        const fetchTipos = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_tipos')
                    .select('id_tipo, nombre_tipo');

                if (error) throw error;

                // Eliminar duplicados
                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_tipo)))
                    .map(nombre_tipo => ({
                        label: nombre_tipo,
                        value: data.find(item => item.nombre_tipo === nombre_tipo).id_tipo
                    }));

                setTipos(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener los tipos:', error.message);
            }
        };

        const fetchIngenieros = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_ingenieros')
                    .select('id_ingeniero, nombre_ingeniero');

                if (error) throw error;

                // Eliminar duplicados
                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_ingeniero)))
                    .map(nombre_ingeniero => ({
                        label: nombre_ingeniero,
                        value: data.find(item => item.nombre_ingeniero === nombre_ingeniero).id_ingeniero
                    }));

                setIngenieros(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener los inges:', error.message);
            }
        };

        fetchEquipos();
        fetchTipos();
        fetchIngenieros();
    }, []);

    // Ahora, vamos a modificar el componente NativeSelect para mostrar el nombre del estatus 
    //junto con el ID. Primero, necesitamos obtener los nombres de los estatus:
    const [estatusOptions, setEstatusOptions] = useState([]);

    // Efecto para cargar las opciones de estatus
    useEffect(() => {
        const fetchEstatus = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_estatus')
                    .select('id_estatus, nombre_estatus');

                if (error) throw error;

                setEstatusOptions(data);
            } catch (error) {
                console.error('Error al obtener los estatus:', error.message);
            }
        };

        fetchEstatus();
    }, []);

    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                id_orden: transactionData.id_orden,
                numero_orden: transactionData.numero_orden,
                descripcion_problema: transactionData.descripcion_problema,
                descripcion_ejecucion: transactionData.descripcion_ejecucion,
                fecha_inicio_orden: transactionData.fecha_inicio_orden,
                fecha_fin_orden: transactionData.fecha_fin_orden,
                id_equipo: transactionData.id_equipo || "", // Usar el ID
                id_estatus: transactionData.id_estatus || "", // Usar el ID
                id_tipo: transactionData.id_tipo || "", // Usar el ID
                id_ingeniero: transactionData.id_ingeniero || "", // Usar el ID


            });

            setSelectedEquipo(transactionData.id_equipo || ""); // Usar el ID
            setSelectedEstatus(transactionData.id_estatus || ""); // Usar el ID
            setSelectedTipo(transactionData.id_tipo || ""); // Usar el ID
            setSelectedIngeniero(transactionData.id_ingeniero || "");

            // Fetch existing files. Recuperar los archivos adjuntos asociados.
            const fetchFiles = async () => {
                try {
                    const { data, error } = await supabase
                        .from('ordenes_archivos')
                        .select('*')
                        .eq('id_orden', transactionData.id_orden);

                    if (error) throw error;

                    setExistingFiles(data);
                } catch (error) {
                    console.error('Error al obtener archivos existentes:', error.message);
                }
            };

            fetchFiles();

        }
    }, [formMode, transactionData]);

    useEffect(() => {
        if (selectedEquipo) {
            const fetchEquipoDetails = async () => {
                try {
                    const { data, error } = await supabase
                        .from('cat_equipos')
                        .select(`
                            nombre_descripcion,
                            numero_serie,
                            numero_inventario,
                            id_marca,
                            cat_marcas!inner(
                                id_marca,
                                nombre_marca
                                ),
                            id_modelo,
                            cat_modelos!inner(
                                id_modelo,
                                nombre_modelo
                                ),
                            id_ubicacion,
                            cat_ubicaciones!inner(
                                id_ubicacion,
                                nombre_ubicacion
                                )
                            
                        `)
                        .eq('id_equipo', selectedEquipo)
                        .single();

                    if (error) throw error;

                    setEquipoDetails({
                        nombre_descripcion: data.nombre_descripcion || "",
                        numero_serie: data.numero_serie || "",
                        numero_inventario: data.numero_inventario || "",
                        nombre_marca: data.cat_marcas.nombre_marca || "",
                        nombre_modelo: data.cat_modelos.nombre_modelo || "",
                        nombre_ubicacion: data.cat_ubicaciones.nombre_ubicacion || ""
                    });
                } catch (error) {
                    console.error('Error al obtener los detalles del equipo:', error.message);
                }
            };

            fetchEquipoDetails();
        } else {
            setEquipoDetails({
                nombre_descripcion: "",
                numero_serie: "",
                nombre_marca: "",
                nombre_modelo: "",
                nombre_ubicacion: ""
            });
        }
    }, [selectedEquipo]);

    // Modificamos handleChange para recalcular el estatus cuando las fechas cambien
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => {
            const newFormData = {
                ...prevFormData,
                [name]: value
            };

            // Recalcular el estatus si cambian las fechas
            if (name === 'fecha_inicio_orden' || name === 'fecha_fin_orden') {
                const { id_estatus, dias_faltantes } = calculateEstatus(newFormData);
                setSelectedEstatus(id_estatus);
                setDiasFaltantes(dias_faltantes);
            }

            return newFormData;
        });
    };

    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'id_equipo':
                setSelectedEquipo(value);
                break;
            case 'id_estatus':
                setSelectedEstatus(value);
                break;
            case 'id_tipo':
                setSelectedTipo(value);
                break;
            case 'id_ingeniero':
                setSelectedIngeniero(value);
            default:
                break;
        }
    };

    // Manejar los archivos adjuntos
    const handleFileDrop = async (files) => {
        try {
            dispatch(ShowLoading());
            console.log('Intentando cargar archivos');

            const uploadPromises = files.map(async (file) => {
                const fileName = `${Date.now()}_${file.name}`;
                console.log('Nombre del archivo al subir:', fileName);

                // Subir el archivo
                const { data: uploadData, error: uploadError } = await supabase.storage
                    .from('bucket_archivos')
                    .upload(fileName, file);

                if (uploadError) {
                    console.error('Error al cargar el archivo:', uploadError);
                    throw uploadError;
                }

                if (!uploadData) {
                    console.error('No se recibieron datos de carga');
                    throw new Error('La carga del archivo no retornó datos');
                }

                console.log('Archivo cargado exitosamente:', uploadData);

                // Obtener la URL pública
                const { data, error: urlError } = await supabase.storage
                    .from('bucket_archivos')
                    .getPublicUrl(fileName);

                if (urlError) {
                    console.error('Error al obtener URL pública:', urlError);
                    throw urlError;
                }

                if (!data || !data.publicUrl) {  // Cambiado de publicURL a publicUrl
                    console.error('Respuesta inesperada al obtener URL pública:', data);
                    throw new Error('No se pudo obtener la URL pública del archivo.');
                }

                console.log('URL pública obtenida:', data.publicUrl);  // Cambiado de publicURL a publicUrl

                return {
                    file,
                    publicURL: data.publicUrl  // Cambiado de publicURL a publicUrl
                };
            });

            const uploadedFiles = await Promise.all(uploadPromises);

            console.log('Todos los archivos procesados. Insertando en la base de datos...');

            const insertPromises = uploadedFiles.map(({ file, publicURL }) =>
                supabase
                    .from('ordenes_archivos')
                    .insert({
                        id_orden: user.id_orden,
                        archivo_url: publicURL,
                        nombre_archivo: file.name,
                        tipo_archivo: file.type,
                        fecha_subida: new Date().toISOString()
                    })
            );

            await Promise.all(insertPromises);
            dispatch(HideLoading());


            console.log('Archivos insertados en la base de datos');

            showNotification({
                title: 'Archivos cargados',
                message: 'Los archivos se han cargado correctamente.',
                color: 'teal',
            });

            // Actualizar la lista de archivos
            const { data: existingFiles, error: fetchError } = await supabase
                .from('ordenes_archivos')
                .select('*')
                .eq('id_orden', user.id_orden);

            if (fetchError) {
                console.error('Error al obtener la lista actualizada de archivos:', fetchError);
                throw fetchError;
            }

            setExistingFiles(existingFiles);
            console.log('Lista de archivos actualizada');

        } catch (error) {
            console.error('Error al cargar archivos:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un problema al cargar los archivos: ' + error.message,
                color: 'red',
            });
        }
    };

    const handleDeleteFile = async (fileUrl) => {
        try {
            dispatch(ShowLoading());
            const fileName = fileUrl.split('/').pop();
            const { error: deleteError } = await supabase.storage
                .from('bucket_archivos')
                .remove([fileName]);

            if (deleteError) throw deleteError;

            await supabase
                .from('ordenes_archivos')
                .delete()
                .eq('archivo_url', fileUrl);

            dispatch(HideLoading());

            showNotification({
                title: 'Archivo eliminado',
                message: 'El archivo se ha eliminado correctamente.',
                color: 'green',
            });

            // Refresh the file list
            const { data, error } = await supabase
                .from('ordenes_archivos')
                .select('*')
                .eq('id_orden', user.id_orden);

            if (error) throw error;

            setExistingFiles(data);
        } catch (error) {
            console.error('Error al eliminar archivo:', error.message);
        }
    };

    const handleUpdate = async () => {
        try {

            await supabase
                .from('ordenes')
                .update({
                    numero_orden: user.numero_orden,
                    descripcion_problema: user.descripcion_problema,
                    descripcion_ejecucion: user.descripcion_ejecucion,
                    fecha_inicio_orden: user.fecha_inicio_orden,
                    fecha_fin_orden: user.fecha_fin_orden,
                    id_equipo: selectedEquipo,
                    id_estatus: selectedEstatus,
                    id_tipo: selectedTipo,
                    id_ingeniero: selectedIngeniero,
                })
                .eq('id_orden', user.id_orden);
            console.log('id estatus a insertar', selectedEstatus);

            showNotification({
                title: 'Orden actualizada',
                message: 'La orden se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar orden:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la orden.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            const fechaInicioUTC = user.fecha_inicio_orden
                ? moment.tz(user.fecha_inicio_orden, 'America/Mexico_City').utc().format()
                : null;
            const fechaFinUTC = user.fecha_fin_orden
                ? moment.tz(user.fecha_fin_orden, 'America/Mexico_City').utc().format()
                : null;

            if (formMode === 'add') {
                const { data, error } = await supabase
                    .from('ordenes')
                    .insert({
                        numero_orden: user.numero_orden,
                        descripcion_problema: user.descripcion_problema,
                        descripcion_ejecucion: user.descripcion_ejecucion,
                        fecha_inicio_orden: fechaInicioUTC,
                        fecha_fin_orden: fechaFinUTC,
                        id_equipo: selectedEquipo,
                        id_estatus: selectedEstatus,
                        id_tipo: selectedTipo,
                        id_ingeniero: selectedIngeniero,
                    });

                if (error) throw error;

                showNotification({
                    title: 'Orden agregada',
                    message: 'La orden ha sido agregada con éxito.',
                    color: 'green',
                });

                setShowForm(false);
                getData();
            } else if (formMode === 'edit') {
                await handleUpdate();
            }
        } catch (error) {
            console.error('Error al agregar/actualizar orden:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la orden.',
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }
    };



    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form onSubmit={handleSubmit}>
                <Divider
                    mt={'10px'}
                >

                </Divider>

                <Group mt={'5px'} justify='flex-start'>
                    <Text
                        c="black"
                        size="lg"
                        fw={350}
                    //style={{ width: '350px' }}
                    >
                        Equipo: {equipoDetails.nombre_descripcion}
                    </Text>
                </Group>
                <Group mt={'5px'} style={{ justifyContent: 'flex-start' }}>
                    <Text
                        c="black"
                        size="sm"
                        fw={350}
                    //style={{ width: '150px' }}
                    >
                        No. Serie: {equipoDetails.numero_serie}
                    </Text>
                    <Text
                        c="black"
                        size="sm"
                        fw={350}
                    //style={{ width: '150px' }}
                    >
                        No. Inventario: {equipoDetails.numero_inventario}
                    </Text>
                    <Text
                        c="black"
                        size="sm"
                        fw={350}
                    //style={{ width: '150px' }}
                    >
                        Marca: {equipoDetails.nombre_marca}
                    </Text>
                    <Text
                        c="black"
                        size="sm"
                        fw={350}
                    //style={{ width: '150px' }}
                    >
                        Modelo: {equipoDetails.nombre_modelo}
                    </Text>
                </Group>
                <Group mt={'5px'} justify='flex-start'>

                </Group>
                <Group mt={'5px'} justify='flex-start'>
                    <Text
                        c="black"
                        size="sm"
                        fw={350}
                        style={{ width: '350px' }}
                    >
                        Ubicación: {equipoDetails.nombre_ubicacion}
                    </Text>

                </Group>
                <Divider
                    mt={'10px'}
                >

                </Divider>
                <Group>
                    <TextInput
                        disabled
                        name="id_orden"
                        label="ID"
                        onChange={handleChange}
                        value={user.id_orden}
                        style={{ width: '150px' }}
                    />
                    <TextInput
                        name="numero_orden"
                        label="No. orden"
                        onChange={handleChange}
                        value={user.numero_orden}
                        style={{ width: '150px' }}
                    />
                    <NativeSelect
                        name="id_equipo"
                        label="Equipo"
                        style={{ width: '255px' }}
                        value={selectedEquipo}
                        onChange={handleChangeSelect}
                        required
                        disabled={formMode === 'edit'}

                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Equipo</option>
                        )}
                        {equipos.map((equipo) => (
                            <option key={equipo.value} value={equipo.value}>
                                {equipo.label}
                            </option>
                        ))}
                    </NativeSelect>
                    <NativeSelect
                        name="id_tipo"
                        label="Tipo"
                        style={{ width: '150px' }}
                        value={selectedTipo}
                        onChange={handleChangeSelect}
                        required

                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Tipo</option>
                        )}
                        {tipos.map((tipo) => (
                            <option key={tipo.value} value={tipo.value}>
                                {tipo.label}
                            </option>
                        ))}
                    </NativeSelect>
                </Group>
                <Group mt={'10px'} mb={'10px'} style={{ alignItems: 'flex-start' }}>
                    <div>

                        <DateTimePicker
                            name="fecha_inicio_orden"
                            label="Fecha Inicio"
                            style={{ width: '150px' }}
                            required
                            value={user.fecha_inicio_orden ? moment.utc(user.fecha_inicio_orden).tz('America/Mexico_City').toDate() : null}
                            onChange={(date) => handleChange({ target: { name: 'fecha_inicio_orden', value: date } })}
                            format="YYYY-MM-DD hh:mm A"
                        />

                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <DateTimePicker
                            name="fecha_fin_orden"
                            label="Fecha Fin"
                            style={{ width: '150px' }}
                            value={user.fecha_fin_orden ? moment.utc(user.fecha_fin_orden).tz('America/Mexico_City').toDate() : null}
                            onChange={(date) => handleChange({ target: { name: 'fecha_fin_orden', value: date } })}
                            format="YYYY-MM-DD hh:mm A"
                        />
                        <Button onClick={handleClearDate} radius="xs" variant="filled" color="red" style={{ marginTop: '10px' }}>
                            <IconEraser size={18} />
                        </Button>

                    </div>
                    <NativeSelect
                        name="id_ingeniero"
                        label="Ingeniero"
                        style={{ width: '200px' }}
                        value={selectedIngeniero}
                        onChange={handleChangeSelect}
                        required

                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Ingeniero</option>
                        )}
                        {ingenieros.map((ingeniero) => (
                            <option key={ingeniero.value} value={ingeniero.value}>
                                {ingeniero.label}
                            </option>
                        ))}
                    </NativeSelect>

                    <NativeSelect
                        name="id_estatus"
                        label="Estatus"
                        style={{ width: '200px' }}
                        value={selectedEstatus || ''}
                        disabled
                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Estatus</option>
                        )}
                        {estatusOptions.map((estatus) => (
                            <option key={estatus.id_estatus} value={estatus.id_estatus}>
                                {`${estatus.id_estatus} - ${estatus.nombre_estatus}`}
                            </option>
                        ))}
                    </NativeSelect>
                </Group>

                <Group style={{ justifyContent: 'center' }}>

                    <Textarea

                        name="descripcion_problema"
                        label="Descripción del Problema"
                        onChange={handleChange}
                        value={user.descripcion_problema}
                        style={{ width: '48%' }}
                        autosize
                        resize='vertical'
                        minRows={2}
                        maxRows={2}
                    />
                    <Textarea

                        name="descripcion_ejecucion"
                        label="Descripción Ejecución"
                        onChange={handleChange}
                        value={user.descripcion_ejecucion}
                        style={{ width: '48%' }}
                        autosize
                        resize='vertical'
                        minRows={2}
                        maxRows={2}
                    />


                </Group>
                <Divider mt={'5px'} mb={'5px'} />
                <div>
                    <Text size="sm" weight={500} mb="sm">Archivos Asociados</Text>
                    <Dropzone
                        onDrop={handleFileDrop}
                        accept={['application/pdf', 'image/jpeg', 'image/png']}
                        maxSize={5 * 1024 ** 2}
                        style={{ marginBottom: '1rem' }}
                    >

                        <Group position="center" spacing="xl" style={{ minHeight: 100, pointerEvents: 'none' }}>
                            <IconFileTypePdf size={40} stroke={1.5} />
                            <IconPhoto size={40} stroke={1.5} />
                            <div>
                                <Text size="xl" inline>
                                    Arrastra los archivos aquí o haz clic para seleccionarlos
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7}>
                                    Archivos permitidos: PDF, JPEG, PNG. Tamaño máximo: 5MB
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>

                    {existingFiles.length > 0 && (
                        <Stack spacing="xs">
                            {existingFiles.map((file) => (
                                <FilePreview
                                    key={file.archivo_url}
                                    file={file}
                                    onView={openFileModal}
                                    onDelete={handleDeleteFile}
                                    onDownload={DownloadFile}
                                />
                            ))}
                        </Stack>
                    )}

                    <Modal
                        opened={modalOpen}
                        onClose={() => setModalOpen(false)}
                        title="Ver archivo"
                        size="xl"
                    >
                        {selectedFile && (
                            <FileViewer
                                fileType={selectedFile.tipo_archivo.split('/')[1]}
                                filePath={selectedFile.archivo_url}
                            />
                        )}
                    </Modal>
                </div>



                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="submit" mt={'10px'}>
                        {formMode === 'edit' ? 'Actualizar' : 'Agregar'}
                    </Button>
                </div>

            </form>
        </div >
    );
}

export default OrdenesForm;

/*
Cuando el NativeSelect del id_estatus era manual
<NativeSelect
                        name="id_estatus"
                        label="Estatus"
                        style={{ width: '150px' }}
                        value={selectedEstatus}
                        onChange={handleChangeSelect}
                        //required
                        disabled

                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Estatus</option>
                        )}
                        {estatus.map((estatus) => (
                            <option key={estatus.value} value={estatus.value}>
                                {estatus.label}
                            </option>
                        ))}
                    </NativeSelect>
*/