import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';

import Login from "../pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from './PublicRoute';

import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { supabase } from '../supabaseClient';

import CatalogoTipos from '../pages/CatalogoTipos';
import CatalogoModelos from '../pages/CatalogoModelos';
import CatalogoMarcas from '../pages/CatalogoMarcas';
import CatalogoIngenieros from '../pages/CatalogoIngenieros';
import CatalogoClasificaciones from '../pages/CatalogoClasificaciones';
import CatalogoEstatuses from '../pages/CatalogoEstatus'
import CatalogoEquipos from '../pages/CatalogoEquipos';
import CatalogoUbicaciones from '../pages/CatalogoUbicaciones';
import CatalogosOrdenes from '../pages/CatalogosOrdenes';


const MainRouter = () => {

    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);

                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        } else {
            //console.log("no hay usuario")
            dispatch(HideLoading());

        }
    }, [user, dispatch]);


    return (
        <div>
            <main>
                <Routes>
                    <Route path='/home' element={<ProtectedRoute><HomePage /></ProtectedRoute>} />


                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_modelos &&
                        permisos.catalogos.modulos.cat_modelos.activo === true && (
                            <Route path='/cat_modelos' element={<ProtectedRoute><CatalogoModelos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_marcas &&
                        permisos.catalogos.modulos.cat_marcas.activo === true && (
                            <Route path='/cat_marcas' element={<ProtectedRoute><CatalogoMarcas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_ingenieros &&
                        permisos.catalogos.modulos.cat_ingenieros.activo === true && (
                            <Route path='/cat_ingenieros' element={<ProtectedRoute><CatalogoIngenieros user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_clasificaciones &&
                        permisos.catalogos.modulos.cat_clasificaciones.activo === true && (
                            <Route path='/cat_clasificaciones' element={<ProtectedRoute><CatalogoClasificaciones user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estatus &&
                        permisos.catalogos.modulos.cat_estatus.activo === true && (
                            <Route path='/cat_estatus' element={<ProtectedRoute><CatalogoEstatuses user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tipos &&
                        permisos.catalogos.modulos.cat_tipos.activo === true && (
                            <Route path='/cat_tipos' element={<ProtectedRoute><CatalogoTipos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_equipos &&
                        permisos.catalogos.modulos.cat_equipos.activo === true && (
                            <Route path='/cat_equipos' element={<ProtectedRoute><CatalogoEquipos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_ubicaciones &&
                        permisos.catalogos.modulos.cat_ubicaciones.activo === true && (
                            <Route path='/cat_ubicaciones' element={<ProtectedRoute><CatalogoUbicaciones user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.ordenes &&
                        permisos.ordenes.activo === true && (
                            <Route path='/ordenes' element={<ProtectedRoute><CatalogosOrdenes user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}









                    {/* Ruta /login, solo mostrar si el usuario no está autenticado */}
                    <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />

                </Routes>
            </main>
        </div>
    );
};

export default MainRouter;

