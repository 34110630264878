import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

function OrdenesCalendar({
    ordenes,
    setSelectedTransaction,
    setFormMode,
    setShowForm,
}) {
    const events = ordenes.map(orden => ({
        id: orden.id_orden,
        
        title: `Orden ${orden.numero_orden} - ${orden.cat_equipos.nombre_descripcion}`,
        start: new Date(orden.fecha_inicio_orden),
        end: orden.fecha_fin_orden ? new Date(orden.fecha_fin_orden) : new Date(orden.fecha_inicio_orden),
        resource: orden
    }));

    const handleSelectEvent = event => {
        setSelectedTransaction(event.resource);
        setFormMode("edit");
        setShowForm(true);
    };

    const eventPropGetter = event => {
        let backgroundColor;
        switch (event.resource.cat_estatus.nombre_estatus) {
            case 'Concluído':
                backgroundColor = 'green';
                break;
            case 'En Proceso':
                backgroundColor = 'orange';
                break;
            case 'Programado':
                backgroundColor = 'blue';
                break;
            default:
                backgroundColor = 'grey';
        }
        return { style: { backgroundColor } };
    };

    return (
        <div style={{ height: '80vh' }}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventPropGetter}
                messages={{
                    next: "Sig",
                    previous: "Ant",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                    agenda: "Agenda",
                    date: "Fecha",
                    time: "Hora",
                    event: "Evento",
                }}
            />
        </div>
    );
}

export default OrdenesCalendar;
