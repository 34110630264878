import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function IngenierosTable({ ingenieros, setSelectedTransaction, setFormMode, setShowForm, getData, filtroIngeniero, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const ingenierosFiltrados = filtroIngeniero
        ? ingenieros.filter(ingeniero => ingeniero.nombre_ingeniero.toLowerCase().includes(filtroIngeniero.toLowerCase()))
        : ingenieros;

    const paginatedTransactions = ingenierosFiltrados.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_ingenieros')
                .delete()
                .eq('id_ingeniero', id);

            if (error) {
                throw new Error('No se pudo borrar el Ingeniero');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Ingeniero borrado',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Ingeniero',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((ingeniero) => (
        <tr key={ingeniero.id_ingeniero}>
            <td>{ingeniero.id_ingeniero}</td>
            <td>{ingeniero.nombre_ingeniero}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_ingenieros &&
                        permisos.catalogos.modulos.cat_ingenieros.acciones &&
                        permisos.catalogos.modulos.cat_ingenieros.acciones.modificar_ingeniero && (
                            <i
                                key={`edit-${ingeniero.id_ingeniero}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(ingeniero);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_ingenieros &&
                        permisos.catalogos.modulos.cat_ingenieros.acciones &&
                        permisos.catalogos.modulos.cat_ingenieros.acciones.eliminar_ingeniero && (
                            <i
                                key={`delete-${ingeniero.id_ingeniero}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este Ingeniero?')) {
                                        deleteTransaction(ingeniero.id_ingeniero);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre ingeniero',
    ];

    const csvData = ingenieros.map((ingeniero) => [
        ingeniero.id_ingeniero,
        ingeniero.nombre_ingeniero,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre ingeniero</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(ingenieros.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                <CsvDownloader
                    filename={'ingenieros.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default IngenierosTable;
