import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function UbicacionesTable({ ubicaciones, setSelectedTransaction, setFormMode, setShowForm, getData, filtroTipo, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const ubicacionesFiltrados = filtroTipo
        ? ubicaciones.filter(ubicacion => ubicacion.nombre_ubicacion.toLowerCase().includes(filtroTipo.toLowerCase()))
        : ubicaciones;

    const paginatedUbicaciones = ubicacionesFiltrados.slice(startIndex, endIndex);

    const deleteTipo = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_ubicaciones')
                .delete()
                .eq('id_ubicacion', id);

            if (error) {
                throw new Error('No se pudo borrar el Ubicacion');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Ubicacion borrado',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Ubicacion',
                color: 'red'
            });
        }
    };

    const getRows = paginatedUbicaciones.map((ubicacion) => (
        <tr key={ubicacion.id_ubicacion}>
            <td>{ubicacion.id_ubicacion}</td>
            <td>{ubicacion.nombre_ubicacion}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_ubicaciones &&
                        permisos.catalogos.modulos.cat_ubicaciones.acciones &&
                        permisos.catalogos.modulos.cat_ubicaciones.acciones.modificar_ubicacion && (
                            <i
                                key={`edit-${ubicacion.id_ubicacion}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(ubicacion);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_ubicaciones &&
                        permisos.catalogos.modulos.cat_ubicaciones.acciones &&
                        permisos.catalogos.modulos.cat_ubicaciones.acciones.eliminar_ubicacion && (
                            <i
                                key={`delete-${ubicacion.id_ubicacion}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este Ubicacion?')) {
                                        deleteTipo(ubicacion.id_ubicacion);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre ubicacion',
    ];

    const csvData = ubicaciones.map((ubicacion) => [
        ubicacion.id_ubicacion,
        ubicacion.nombre_ubicacion,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre ubicacion</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(ubicaciones.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                <CsvDownloader
                    filename={'ubicaciones.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default UbicacionesTable;
