import React, { useEffect, useState } from 'react';
import { AppShell, Burger, Group, Button, AppShellFooter, Code } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import SideNavbar from './SideNavbar';
import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { Navigate } from 'react-router-dom';
import Spinner from './Spinner';
import '../index.css';




export function AppShellWithNavbar({ children }) {
  const { user, signOut, loading } = useAuth();
  const [permisos, setPermisos] = useState([]);

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const dispatch = useDispatch();


  useEffect(() => {
    if (user) {
      const fetchUserPermissions = async () => {
        try {
          dispatch(ShowLoading());
          const { data: profile, error } = await supabase
            .from('profiles')
            .select('acciones')
            .eq('id', user.id)
            .single();

          if (error) {
            //console.error("Error fetching user profile:", error.message);
            throw error;
          }

          if (profile) {
            const userPermissions = profile.acciones || {};
            setPermisos(userPermissions);
            //console.log("Permisos:", userPermissions);
          }

        } catch (error) {
          //console.error("Error fetching user permissions:", error.message);
        } finally {
          dispatch(HideLoading());
        }
      };

      fetchUserPermissions();
    }
  }, [user, dispatch]);

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      //console.error('Error al cerrar sesión:', error.message);
    }
  };

  // Codigo para que si intentan acceder a una ruta protegida sin estar loggeados los manden a Login

  if (!user && !loading) {
    return <Navigate to="/login" />;
    //dispatch(ShowLoading());
  }

  // Mostrar spinner mientras se verifica el estado de autenticación
  if (loading) {
    return (
      <div className="loader-parent">
        <Spinner />
      </div>
    );
  }

  return (
    <AppShell
      layout='alt'
      header={{ height: 60 }}
      navbar={{
        width: 250,
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      /*
      aside={{
        width: 70,
        breakpoint: 'md',
        collapsed: { desktop: false, mobile: true },
      }}
      */
      padding="md"
    >
      <AppShell.Header className='HeaderCard'>
        <Group h="100%" justify="space-between" px="md">
          <Group>
            {/* Burger en el extremo izquierdo */}
            <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
            {!desktopOpened && (
              <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
            )}
          </Group>

          <Group>
            {user && (
              <Button onClick={handleSignOut} variant="outline" color="rgba(0, 0, 0, 1)">
                Cerrar sesión
              </Button>
            )}
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="sm" className='NavBarCard' style={{ backgroundColor: '#002f4f' }}>
        <Group>
          {/* Burger en el extremo izquierdo */}
          <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm" size="sm"
            color='white'
          />
          <Burger
            opened={desktopOpened}
            onClick={toggleDesktop}
            visibleFrom="sm" size="sm"
            color='white'

          />
          <h3 style={{ color: 'white' }} >Mantenimiento</h3>
        </Group>
        <SideNavbar user={user} permisos={permisos} />
      </AppShell.Navbar>

      <AppShell.Main style={{ backgroundColor: '#f0f0f0' }}>{children}</AppShell.Main>
      <AppShellFooter>
        <Code>2024</Code>
      </AppShellFooter>
    </AppShell>
  );
}