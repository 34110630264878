// ModelosTable.js
import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function ModelosTable({ modelos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroModelo, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Aplicar filtro por nombre del modelo si se ha proporcionado un filtro
    const modelosFiltrados = filtroModelo
        ? modelos.filter(modelo => modelo.nombre_modelo.toLowerCase().includes(filtroModelo.toLowerCase()))
        : modelos;

    const paginatedTransactions = modelosFiltrados.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_modelos')
                .delete()
                .eq('id_modelo', id);

            if (error) {
                throw new Error('No se pudo borrar el Modelo');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Modelo borrado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Modelo',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((modelo) => (
        <tr key={modelo.id_modelo}>
            <td>{modelo.id_modelo}</td>
            <td>{modelo.nombre_modelo}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_modelos &&
                        permisos.catalogos.modulos.cat_modelos.acciones &&
                        permisos.catalogos.modulos.cat_modelos.acciones.modificar_modelo && (
                            <i
                                key={`edit-${modelo.id_modelo}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(modelo);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_modelos &&
                        permisos.catalogos.modulos.cat_modelos.acciones &&
                        permisos.catalogos.modulos.cat_modelos.acciones.eliminar_modelo && (
                            <i
                                key={`delete-${modelo.id_modelo}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar este Modelo?')) {
                                        deleteTransaction(modelo.id_modelo);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre modelo',
    ];

    const csvData = modelos.map((modelo) => [
        modelo.id_modelo,
        modelo.nombre_modelo,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre modelo</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(modelos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                <CsvDownloader
                    filename={'modelos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default ModelosTable;
