import React, { useState } from 'react'
import { supabase } from "../supabaseClient";
import { Group, Button, Modal, Card, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from '@tabler/icons-react';
import OrdenesTable from '../components/OrdenesTable';
import OrdenesFiltros from '../components/OrdenesFiltros';
import OrdenesForm from '../components/OrdenesForm';
import OrdenesCalendar from '../components/OrdenesCalendar';


function CatalogosOrdenes({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [ordenes, setOrdenes] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [view, setView] = useState('table');



    //para Filtros Pagos
    const [filters, setFilters] = React.useState({
        numero_serie: "",
        numero_inventario: "",
        nombre_descripcion: "",
        nombre_modelo: "",
        nombre_marca: "",
        nombre_clasificacion: "",
        nombre_ubicacion: "",
        fechaInicial: null,
        fechaFinal: null,

    });

    const formatFecha = (fecha) => {
        if (!fecha) return null;
        const fechaActividad = new Date(fecha);
        const fechaCDMX = new Date(fechaActividad.toLocaleString('en-US', { timeZone: 'America/Mexico_City' }));
        return fechaCDMX.toISOString().slice(0, 19).replace('T', ' ');
    };


    const fetchOrdenes = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase
                .from('ordenes')
                .select(`
                    id_orden,
                    numero_orden,
                    descripcion_problema,
                    descripcion_ejecucion,
                    fecha_inicio_orden,
                    fecha_fin_orden,
                    id_equipo,
                    cat_equipos!inner (
                        id_equipo,
                        nombre_descripcion,
                        numero_serie,
                        numero_inventario,
                        id_marca,
                        cat_marcas!inner (
                            id_marca,
                            nombre_marca
                        ),
                        id_modelo,
                        cat_modelos!inner (
                            id_modelo,
                            nombre_modelo
                        ),
                        id_clasificacion,
                        cat_clasificaciones!inner (
                            id_clasificacion,
                            nombre_clasificacion
                        ),
                        id_ubicacion,
                        cat_ubicaciones!inner (
                            id_ubicacion,
                            nombre_ubicacion
                        )
                    ),
                    id_estatus,
                    cat_estatus!inner (
                        id_estatus,
                        nombre_estatus
                    ),
                    id_tipo,
                    cat_tipos!inner (
                        id_tipo,
                        nombre_tipo
                    ),
                    id_ingeniero,
                    cat_ingenieros!inner(
                        id_ingeniero,
                        nombre_ingeniero
                        )
                `);

            // Aplicar filtros
            if (filters.nombre_ubicacion) {
                supabaseQuery = supabaseQuery.eq('cat_equipos.cat_ubicaciones.nombre_ubicacion', filters.nombre_ubicacion);
            }
            if (filters.numero_serie) {
                supabaseQuery = supabaseQuery.eq('cat_equipos.numero_serie', filters.numero_serie);
            }
            if (filters.numero_inventario) {
                supabaseQuery = supabaseQuery.eq('cat_equipos.numero_inventario', filters.numero_inventario);
            }
            if (filters.nombre_descripcion) {
                supabaseQuery = supabaseQuery.eq('cat_equipos.nombre_descripcion', filters.nombre_descripcion);
            }
            if (filters.nombre_modelo) {
                supabaseQuery = supabaseQuery.eq('cat_equipos.cat_modelos.nombre_modelo', filters.nombre_modelo);
            }
            if (filters.nombre_marca) {
                supabaseQuery = supabaseQuery.eq('cat_equipos.cat_marcas.nombre_marca', filters.nombre_marca);
            }
            if (filters.nombre_clasificacion) {
                supabaseQuery = supabaseQuery.eq('cat_equipos.cat_clasificaciones.nombre_clasificacion', filters.nombre_clasificacion);
            }
            if (filters.nombre_estatus) {
                supabaseQuery = supabaseQuery.eq('cat_estatus.nombre_estatus', filters.nombre_estatus);
            }
            if (filters.nombre_ingeniero) {
                supabaseQuery = supabaseQuery.eq('cat_ingenieros.nombre_ingeniero', filters.nombre_ingeniero);
            }
            if (filters.fechaInicial) {
                supabaseQuery = supabaseQuery.gte('fecha_inicio_orden', formatFecha(filters.fechaInicial));
            }
            if (filters.fechaFinal) {
                supabaseQuery = supabaseQuery.lte('fecha_fin_orden', formatFecha(filters.fechaFinal));
            }

            // Ordenar y ejecutar la consulta
            let { data: ordenes, error } = await supabaseQuery.order('id_orden', { ascending: false });

            if (error) throw error;

            console.log("Órdenes obtenidas:", ordenes);
            setOrdenes(ordenes);
            dispatch(HideLoading());

        } catch (error) {
            console.error('Error al obtener órdenes:', error.message);
            dispatch(HideLoading());
            showNotification({
                title: "Error obteniendo información",
                message: error.message,
                color: "red"
            });
        }
    };

    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            await fetchOrdenes();
        } catch (error) {
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };


    const canAddOrden = permisos &&
        permisos.ordenes &&
        permisos.ordenes.acciones &&
        permisos.ordenes.acciones.registrar_orden === true;

    return (
        <div>

            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <OrdenesFiltros
                            filters={filters}
                            setFilters={setFilters}
                        />
                        <Group mt={'20px'}>
                            <Button leftSection={<IconSearch size={20} />} color="#005187" onClick={getData}>
                                Buscar Órdenes de Servicio
                            </Button>

                            {canAddOrden && (

                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Orden
                                </Button>
                            )}
                            <Button.Group >
                                <Button variant="" color={view === 'table' ? 'blue' : 'gray'} onClick={() => setView('table')}>
                                    Tabla
                                </Button>
                                <Button variant="" color={view === 'calendar' ? 'blue' : 'gray'} onClick={() => setView('calendar')}>
                                    Calendario
                                </Button>
                            </Button.Group>


                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Orden" : "Editar Orden"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <OrdenesForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />


                </Modal>



                <div style={{ overflowX: "auto" }}>

                    {view === 'table' ? (

                        <OrdenesTable
                            ordenes={ordenes}
                            setSelectedTransaction={setSelectedTransaction}
                            setFormMode={setFormMode}
                            setShowForm={setShowForm}
                            getData={getData}
                            user={user}
                            permisos={permisos}
                        />
                    ) : (
                        <OrdenesCalendar
                            ordenes={ordenes}
                            setSelectedTransaction={setSelectedTransaction}
                            setFormMode={setFormMode}
                            setShowForm={setShowForm}
                            getData={getData}
                            user={user}
                            permisos={permisos}
                        />
                    )}


                </div>
            </Card>
        </div>

    );

}


export default CatalogosOrdenes;