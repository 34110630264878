// MarcasTable.js
import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function MarcasTable({ marcas, setSelectedTransaction, setFormMode, setShowForm, getData, filtroMarca, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const marcasFiltradas = filtroMarca
        ? marcas.filter(marca => marca.nombre_marca.toLowerCase().includes(filtroMarca.toLowerCase()))
        : marcas;

    const paginatedTransactions = marcasFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_marcas')
                .delete()
                .eq('id_marca', id);

            if (error) {
                throw new Error('No se pudo borrar la Marca');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Marca borrada',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la Marca',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((marca) => (
        <tr key={marca.id_marca}>
            <td>{marca.id_marca}</td>
            <td>{marca.nombre_marca}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_marcas &&
                        permisos.catalogos.modulos.cat_marcas.acciones &&
                        permisos.catalogos.modulos.cat_marcas.acciones.modificar_marca && (
                            <i
                                key={`edit-${marca.id_marca}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(marca);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_marcas &&
                        permisos.catalogos.modulos.cat_marcas.acciones &&
                        permisos.catalogos.modulos.cat_marcas.acciones.eliminar_marca && (
                            <i
                                key={`delete-${marca.id_marca}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar esta Marca?')) {
                                        deleteTransaction(marca.id_marca);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre marca',
    ];

    const csvData = marcas.map((marca) => [
        marca.id_marca,
        marca.nombre_marca,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Nombre marca</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(marcas.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                <CsvDownloader
                    filename={'marcas.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default MarcasTable;
