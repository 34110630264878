import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';

function EquiposTable({ equipos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroEquipo, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const equiposFiltrados = filtroEquipo
        ? equipos.filter(equipo => equipo.nombre_equipo.toLowerCase().includes(filtroEquipo.toLowerCase()))
        : equipos;

    const paginatedTransactions = equiposFiltrados.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_equipos')
                .delete()
                .eq('id_equipo', id);

            if (error) {
                throw new Error('No se pudo borrar el Equipo');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Equipo borrado',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Equipo',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((equipo) => (
        <tr key={equipo.id_equipo}>
            <td>{equipo.id_equipo}</td>
            <td>{equipo.cat_ubicaciones.nombre_ubicacion}</td>
            <td>{equipo.nombre_descripcion}</td>
            <td>{equipo.numero_serie}</td>
            <td>{equipo.numero_inventario}</td>
            <td>{equipo.cat_marcas.nombre_marca}</td>
            <td>{equipo.cat_modelos.nombre_modelo}</td>




            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_equipos &&
                        permisos.catalogos.modulos.cat_equipos.acciones &&
                        permisos.catalogos.modulos.cat_equipos.acciones.modificar_equipo && (
                            <i
                                key={`edit-${equipo.id_equipo}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(equipo);
                                    setFormMode("edit");
                                    setShowForm(true);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_equipos &&
                        permisos.catalogos.modulos.cat_equipos.acciones &&
                        permisos.catalogos.modulos.cat_equipos.acciones.eliminar_equipo && (
                            <i
                                key={`delete-${equipo.id_equipo}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    if (window.confirm('¿Estás seguro de eliminar este Equipo?')) {
                                        deleteTransaction(equipo.id_equipo);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre equipo',
    ];

    const csvData = equipos.map((equipo) => [
        equipo.id_equipo,
        equipo.nombre_equipo,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID</th>
                        <th>Ubicación</th>

                        <th>Nombre equipo</th>
                        <th>No. Serie</th>
                        <th>No. inventario</th>
                        <th>Marca</th>
                        <th>Modelo</th>



                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(equipos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                <CsvDownloader
                    filename={'equipos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default EquiposTable;
