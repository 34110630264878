import React, { useState } from 'react';
import { Card, Text, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';


function EquiposCard({ equipos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroEquipo, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const dispatch = useDispatch();

    const equiposFiltrados = filtroEquipo
        ? equipos.filter(equipo => equipo.nombre_equipo.toLowerCase().includes(filtroEquipo.toLowerCase()))
        : equipos;

    const paginatedTransactions = equiposFiltrados.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            const { error } = await supabase
                .from('cat_equipos')
                .delete()
                .eq('id_equipo', id);

            if (error) {
                throw new Error('No se pudo borrar el Equipo');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Equipo borrado',
                color: 'green'
            });

            getData();
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Equipo',
                color: 'red'
            });
        }
    };

    const getCards = paginatedTransactions.map((equipo) => (

        <Card
            key={equipo.id_equipo}
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            style={{ borderColor: '#d1d1d1', position: 'relative' }}
        >
            <div style={{ paddingBottom: '45px' }}> {/* Añade espacio en la parte inferior */}
                <Text size="lg" weight={500}>
                    {equipo.nombre_descripcion}
                </Text>
                <Text size="sm" color="dimmed">
                    Ubicación: {equipo.cat_ubicaciones.nombre_ubicacion}
                </Text>
                <Text size="sm" color="dimmed">
                    No. Serie: {equipo.numero_serie}
                </Text>
                <Text size="sm" color="dimmed">
                    No. Inventario: {equipo.numero_inventario}
                </Text>
                <Text size="sm" color="dimmed">
                    Marca: {equipo.cat_marcas.nombre_marca}
                </Text>
                <Text size="sm" color="dimmed">
                    Modelo: {equipo.cat_modelos.nombre_modelo}
                </Text>
            </div>
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '20px',
                borderTop: '1px solid #d1d1d1',
                backgroundColor: 'white', //color de la parte de iconos
                //padding: '10px 0',
                minHeight: '50px' // Asegura que haya suficiente espacio para los iconos
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRight: '1px solid #d1d1d1',

                }}>
                    {permisos?.catalogos?.modulos?.cat_equipos?.acciones?.modificar_equipo && (
                        <i
                            className='ri-pencil-line'

                            onClick={() => {
                                setSelectedTransaction(equipo);
                                setFormMode("edit");
                                setShowForm(true);
                            }}
                        />
                    )}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedTransaction(equipo);
                            setFormMode("edit");
                            setShowForm(true);
                        }}
                        style={{
                            marginLeft: '8px',
                            color: 'black',
                            fontFamily: 'Arimo',
                            textDecoration: 'none', // Opcional: para quitar el subrayado
                            cursor: 'pointer',
                            fontSize: '16px' // Ajusta el tamaño si es necesario
                        }}
                    >
                        Editar
                    </a>

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {permisos?.catalogos?.modulos?.cat_equipos?.acciones?.eliminar_equipo && (
                        <i
                            className='ri-delete-bin-line'

                            onClick={() => {
                                if (window.confirm('¿Estás seguro de eliminar este Equipo?')) {
                                    deleteTransaction(equipo.id_equipo);
                                }
                            }}
                        />
                    )}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            if (window.confirm('¿Estás seguro de eliminar este Equipo?')) {
                                deleteTransaction(equipo.id_equipo);
                            }
                        }}
                        style={{
                            marginLeft: '8px',
                            color: 'black',
                            fontFamily: 'Arimo',
                            textDecoration: 'none', // Opcional: para quitar el subrayado
                            cursor: 'pointer',
                            fontSize: '16px' // Ajusta el tamaño si es necesario
                        }}
                    >
                        Eliminar
                    </a>
                </div>
            </div>
        </Card>

    ));

    const csvHeaders = [
        'ID',
        'Nombre equipo',
    ];

    const csvData = equipos.map((equipo) => [
        equipo.id_equipo,
        equipo.nombre_equipo,
    ]);

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '20px', marginBottom: '20px', padding: '20px' }}>
                {getCards}
            </div>
            <Group justify="flex-end" mb={10} mt={20}>
                <Pagination
                    total={Math.ceil(equipos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'equipos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default EquiposCard;
