import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import ClasificacionesTable from '../components/ClasificacionesTable';
import ClasificacionesForm from '../components/ClasificacionesForm';
import { Group, Button, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";

function CatalogoClasificaciones({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [clasificaciones, setClasificaciones] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroClasificacion, setFiltroClasificacion] = useState('');

    useEffect(() => {
        try {
            const getClasificaciones = async () => {
                dispatch(ShowLoading());
                const allClasificaciones = await fetchClasificaciones();
                setClasificaciones(allClasificaciones);
                dispatch(HideLoading());
            }
            getClasificaciones();
        } catch (error) {
        }
    }, [dispatch]);

    const fetchClasificaciones = async () => {
        try {
            let { data: clasificaciones, error } = await supabase
                .from('cat_clasificaciones')
                .select(`
                    id_clasificacion,
                    nombre_clasificacion
                `)
                .order('id_clasificacion', { ascending: true });

            if (error) {
                throw error;
            }
            return clasificaciones;
        } catch (error) {
            throw error;
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: clasificacionesFiltradas, error } = await supabase
                .from('cat_clasificaciones')
                .select(`
                    id_clasificacion,
                    nombre_clasificacion
                `)
                .ilike('nombre_clasificacion', `%${filtroClasificacion.toLowerCase()}%`)
                .order('id_clasificacion', { ascending: true });

            if (error) {
                throw error;
            }
            setClasificaciones(clasificacionesFiltradas);
        } catch (error) {
            showNotification({
                title: 'Error al filtrar clasificaciones',
                message: error.message,
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };

    const getData = async () => {
        try {
            const allClasificaciones = await fetchClasificaciones();
            setClasificaciones(allClasificaciones);
        } catch (error) {
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };

    const canAddClasificacion = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_clasificaciones &&
        permisos.catalogos.modulos.cat_clasificaciones.acciones &&
        permisos.catalogos.modulos.cat_clasificaciones.acciones.registrar_clasificacion === true;

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl">
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel">
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar clasificación"
                                value={filtroClasificacion}
                                onChange={(event) => setFiltroClasificacion(event.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddClasificacion && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}
                                >
                                    + Agregar Clasificación
                                </Button>
                            )}
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Clasificación" : "Editar Clasificación"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <ClasificacionesForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction}
                    />
                </Modal>
                <div style={{ overflowX: "auto" }}>
                    <ClasificacionesTable
                        clasificaciones={clasificaciones}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>
    );
}

export default CatalogoClasificaciones;
