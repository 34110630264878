import React, { useEffect, useState } from 'react';
import { Stack, TextInput, NativeSelect, Group, Button } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";

function EquiposForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();

    const [user, setUser] = useState({
        id_equipo: "",
        numero_serie: "",
        numero_inventario: "",
        nombre_descripcion: "",
        id_modelo: "",
        nombre_modelo: "",
        id_marca: "",
        nombre_marca: "",
        id_clasificacion: "",
        nombre_clasificacion: "",
        id_ubicacion: "",
        nombre_ubicacion: "",
    });

    useEffect(() => {
        if (!user.id_equipo) {
            setUser({
                id_equipo: "",
                numero_serie: "",
                numero_inventario: "",
                nombre_descripcion: "",
                id_modelo: "",
                nombre_modelo: "",
                id_marca: "",
                nombre_marca: "",
                id_clasificacion: "",
                nombre_clasificacion: "",
                id_ubicacion: "",
                nombre_ubicacion: "",
            });
        }
    }, [user.id_equipo]);

    const [modelos, setModelos] = useState([]);
    const [selectedModelo, setSelectedModelo] = useState("");

    const [marcas, setMarcas] = useState([]);
    const [selectedMarca, setSelectedMarca] = useState("");

    const [clasificaciones, setClasificaciones] = useState([]);
    const [selectedClasificacion, setSelectedClasificacion] = useState("");

    const [ubicaciones, setUbicaciones] = useState([]);
    const [selectedUbicacion, setSelectedUbicaciones] = useState("");

    useEffect(() => {
        const fetchMarcas = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_marcas')
                    .select('id_marca, nombre_marca');

                if (error) throw error;

                // Eliminar duplicados
                const uniqueMarcas = Array.from(new Set(data.map(item => item.nombre_marca)))
                    .map(nombre_marca => ({
                        label: nombre_marca,
                        value: data.find(item => item.nombre_marca === nombre_marca).id_marca
                    }));

                setMarcas(uniqueMarcas);
            } catch (error) {
                console.error('Error al obtener las marcas:', error.message);
            }
        };

        const fetchModelos = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_modelos')
                    .select('id_modelo, nombre_modelo');

                if (error) throw error;

                // Eliminar duplicados
                const uniqueModelos = Array.from(new Set(data.map(item => item.nombre_modelo)))
                    .map(nombre_modelo => ({
                        label: nombre_modelo,
                        value: data.find(item => item.nombre_modelo === nombre_modelo).id_modelo
                    }));

                setModelos(uniqueModelos);
            } catch (error) {
                console.error('Error al obtener los modelos:', error.message);
            }
        };

        const fetchClasificaciones = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_clasificaciones')
                    .select('id_clasificacion, nombre_clasificacion');

                if (error) throw error;

                // Eliminar duplicados
                const uniqueClasificaciones = Array.from(new Set(data.map(item => item.nombre_clasificacion)))
                    .map(nombre_clasificacion => ({
                        label: nombre_clasificacion,
                        value: data.find(item => item.nombre_clasificacion === nombre_clasificacion).id_clasificacion
                    }));

                setClasificaciones(uniqueClasificaciones);
            } catch (error) {
                console.error('Error al obtener las clasificaciones:', error.message);
            }
        };

        const fetchUbicaciones = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_ubicaciones')
                    .select('id_ubicacion, nombre_ubicacion');

                if (error) throw error;

                // Eliminar duplicados
                const uniqueUbicaciones = Array.from(new Set(data.map(item => item.nombre_ubicacion)))
                    .map(nombre_ubicacion => ({
                        label: nombre_ubicacion,
                        value: data.find(item => item.nombre_ubicacion === nombre_ubicacion).id_ubicacion
                    }));

                setUbicaciones(uniqueUbicaciones);
            } catch (error) {
                console.error('Error al obtener las ubicaciones:', error.message);
            }
        };

        fetchMarcas();
        fetchModelos();
        fetchClasificaciones();
        fetchUbicaciones();
    }, []);

    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                id_equipo: transactionData.id_equipo,
                numero_serie: transactionData.numero_serie,
                numero_inventario: transactionData.numero_inventario,
                nombre_descripcion: transactionData.nombre_descripcion,
                id_marca: transactionData.cat_marcas ? transactionData.cat_marcas.id_marca : "",
                nombre_marca: transactionData.nombre_marca,
                id_modelo: transactionData.cat_modelos ? transactionData.cat_modelos.id_modelo : "",
                nombre_modelo: transactionData.nombre_modelo,
                id_clasificacion: transactionData.cat_clasificaciones ? transactionData.cat_clasificaciones.id_clasificacion : "",
                nombre_clasificacion: transactionData.nombre_clasificacion,
                id_ubicacion: transactionData.cat_ubicaciones ? transactionData.cat_ubicaciones.id_ubicacion : "",
                nombre_ubicacion: transactionData.nombre_ubicacion,
            });

            setSelectedMarca(transactionData.cat_marcas ? transactionData.cat_marcas.id_marca : "");
            setSelectedModelo(transactionData.cat_modelos ? transactionData.cat_modelos.id_modelo : "");
            setSelectedClasificacion(transactionData.cat_clasificaciones ? transactionData.cat_clasificaciones.id_clasificacion : "");
            setSelectedUbicaciones(transactionData.cat_ubicaciones ? transactionData.cat_ubicaciones.id_ubicacion : "");
        }
    }, [formMode, transactionData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'id_marca':
                setSelectedMarca(value);
                break;
            case 'id_modelo':
                setSelectedModelo(value);
                break;
            case 'id_clasificacion':
                setSelectedClasificacion(value);
                break;
            case 'id_ubicacion':
                setSelectedUbicaciones(value);
                break;
            default:
                break;
        }
    };


    const handleUpdate = async () => {
        try {
            const { data, error } = await supabase
                .from('cat_equipos')
                .select('numero_serie')
                .eq('id_equipo', user.id_equipo)
                .single();

            if (error) throw error;

            if (user.numero_serie !== data.numero_serie) {
                const { data, error } = await supabase
                    .from('cat_equipos')
                    .select('numero_serie')
                    .eq('numero_serie', user.numero_serie)
                    .limit(1);

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'Número de serie ya existe.',
                        color: 'red',
                    });
                    return;
                }
            }

            await supabase
                .from('cat_equipos')
                .update({
                    numero_serie: user.numero_serie,
                    numero_inventario: user.numero_inventario,
                    nombre_descripcion: user.nombre_descripcion,
                    id_marca: selectedMarca,
                    id_modelo: selectedModelo,
                    id_clasificacion: selectedClasificacion,
                    id_ubicacion: selectedUbicacion,
                })
                .eq('id_equipo', user.id_equipo);

            showNotification({
                title: 'Equipo actualizado',
                message: 'El equipo se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar equipo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el equipo.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {
                const { data, error } = await supabase
                    .from('cat_equipos')
                    .select('numero_serie')
                    .eq('numero_serie', user.numero_serie)
                    .limit(1);

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'Número de serie ya existe.',
                        color: 'red',
                    });
                    dispatch(HideLoading());
                    return;
                } else {
                    await supabase
                        .from('cat_equipos')
                        .insert({
                            numero_serie: user.numero_serie,
                            numero_inventario: user.numero_inventario,
                            nombre_descripcion: user.nombre_descripcion,
                            id_marca: selectedMarca,
                            id_modelo: selectedModelo,
                            id_clasificacion: selectedClasificacion,
                            id_ubicacion: selectedUbicacion,

                        });

                    showNotification({
                        title: 'Equipo agregado',
                        message: 'El equipo ha sido agregado con éxito.',
                        color: 'green',
                    });
                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();
                }
            } else if (formMode === 'edit') {
                await handleUpdate();
            }
            dispatch(HideLoading());
        } catch (error) {
            console.error('Error al agregar/actualizar equipo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el equipo.',
                color: 'red',
            });
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form onSubmit={handleSubmit}>
                <Group>
                    <TextInput
                        name="nombre_descripcion"
                        label="Descripción"
                        style={{ width: '100%' }}
                        onChange={handleChange}
                        value={user.nombre_descripcion}
                        placeholder='Descripción'
                        required

                    />
                </Group>
                <Group>
                    <TextInput
                        disabled
                        name="id_equipo"
                        label="ID"
                        onChange={handleChange}
                        value={user.id_equipo}
                        style={{ width: '200px' }}
                    />
                    <NativeSelect
                        name="id_ubicacion"
                        label="Ubicación"
                        style={{ width: '200px' }}
                        value={selectedUbicacion}
                        onChange={handleChangeSelect}
                        required
                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Ubicación</option>
                        )}
                        {ubicaciones.map((ubicacion) => (
                            <option key={ubicacion.value} value={ubicacion.value}>
                                {ubicacion.label}
                            </option>
                        ))}
                    </NativeSelect>
                </Group>
                <Group>
                    <TextInput
                        name="numero_serie"
                        label="Num. serie"
                        style={{ width: '200px' }}
                        required
                        onChange={handleChange}
                        value={user.numero_serie}
                        placeholder='Num serie'
                    />
                    <TextInput
                        name="numero_inventario"
                        label="Num. inventario"
                        style={{ width: '200px' }}
                        onChange={handleChange}
                        value={user.numero_inventario}
                        placeholder='Num inventario'
                        required

                    />
                </Group>
                <Group>
                    <NativeSelect
                        name="id_clasificacion"
                        label="Clasificación"
                        style={{ width: '100%' }}
                        value={selectedClasificacion}
                        onChange={handleChangeSelect}
                        required

                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Clasificación</option>
                        )}
                        {clasificaciones.map((clasificacion) => (
                            <option key={clasificacion.value} value={clasificacion.value}>
                                {clasificacion.label}
                            </option>
                        ))}
                    </NativeSelect>
                </Group>

                <Group>
                    <NativeSelect
                        name="id_marca"
                        label="Marca"
                        style={{ width: '200px' }}
                        value={selectedMarca}
                        onChange={handleChangeSelect}
                        required

                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Marca</option>
                        )}
                        {marcas.map((marca) => (
                            <option key={marca.value} value={marca.value}>
                                {marca.label}
                            </option>
                        ))}
                    </NativeSelect>

                    <NativeSelect
                        name="id_modelo"
                        label="Modelo"
                        style={{ width: '200px' }}
                        value={selectedModelo}
                        onChange={handleChangeSelect}
                        required

                    >
                        {formMode === 'add' && (
                            <option value="">Seleccione Modelo</option>
                        )}
                        {modelos.map((modelo) => (
                            <option key={modelo.value} value={modelo.value}>
                                {modelo.label}
                            </option>
                        ))}
                    </NativeSelect>
                </Group>


                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="submit" mt={'10px'}>
                        {formMode === 'edit' ? 'Actualizar' : 'Agregar'}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default EquiposForm;
