import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Button, Group } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";

function UbicacionsForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    // Valores iniciales
    const [ubicacion, setUbicacion] = useState({
        nombre_ubicacion: "",
        id_ubicacion: "" // Inicializar id_ubicacion como una cadena vacía
    });

    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUbicacion({
                nombre_ubicacion: transactionData.nombre_ubicacion,
                id_ubicacion: transactionData.id_ubicacion
            });
        }
    }, [formMode, transactionData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUbicacion(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateUbicacion = async () => {
        try {
            await supabase
                .from('cat_ubicaciones')
                .update({
                    nombre_ubicacion: ubicacion.nombre_ubicacion
                })
                .eq('id_ubicacion', ubicacion.id_ubicacion);

            showNotification({
                title: 'Ubicacion actualizado',
                message: 'El Ubicacion se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar el Ubicacion:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el Ubicacion.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (formMode === 'add') {
                const { error } = await supabase
                    .from('cat_ubicaciones')
                    .insert({
                        nombre_ubicacion: ubicacion.nombre_ubicacion
                    });

                if (error) {
                    throw error;
                }

                showNotification({
                    title: 'Ubicacion agregado',
                    message: 'El Ubicacion ha sido agregado con éxito.',
                    color: 'green',
                });
            } else if (formMode === 'edit') {
                await handleUpdateUbicacion();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al agregar/actualizar Ubicacion:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el Ubicacion.',
                color: 'red',
            });
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action="" onSubmit={handleSubmit}>
                <Group>
                    <Stack>
                        <TextInput
                            disabled
                            name="id_ubicacion"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={ubicacion.id_ubicacion}
                        />

                        <TextInput
                            name="nombre_ubicacion"
                            label="Nombre Ubicacion"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={ubicacion.nombre_ubicacion} // Asignar el valor del estado al campo
                            placeholder='Nombre Ubicacion'
                        />
                    </Stack>
                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Ubicacion" : "Editar Ubicacion"}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default UbicacionsForm;
