import '@mantine/dates/styles.css';
import React, { useEffect, useState } from 'react';
import { Group, Autocomplete, Select } from '@mantine/core';
import { supabase } from "../supabaseClient";
import { rem } from '@mantine/core';

function EquiposFiltros({ setFilters, filters, getData }) {
    const [numeroserie, setNumeroserie] = useState([]);
    const [numeroinventario, setNumeroinventario] = useState([]);
    const [descripciones, setDescripciones] = useState([]);
    const [modelos, setModelos] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [clasificaciones, setClasificaciones] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);


    useEffect(() => {
        const fetchClasificaciones = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_clasificaciones')
                    .select('nombre_clasificacion');

                if (error) {
                    throw error;
                }

                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_clasificacion)))
                    .map(nombre_clasificacion => ({
                        label: nombre_clasificacion,
                        value: nombre_clasificacion
                    }));

                setClasificaciones(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener las clasificaciones:', error.message);
            }
        };
        const fetchNumeroserie = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_equipos')
                    .select('numero_serie');

                if (error) {
                    throw error;
                }

                const uniqueOptions = Array.from(new Set(data.map(item => item.numero_serie)))
                    .map(numero_serie => ({
                        label: numero_serie,
                        value: numero_serie
                    }));

                setNumeroserie(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener los números de serie:', error.message);
            }
        };

        const fetchNumeroinventario = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_equipos')
                    .select('numero_inventario');

                if (error) {
                    throw error;
                }

                const uniqueOptions = Array.from(new Set(data.map(item => item.numero_inventario)))
                    .map(numero_inventario => ({
                        label: numero_inventario,
                        value: numero_inventario
                    }));

                setNumeroinventario(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener los números de inventario:', error.message);
            }
        };

        const fetchDescripciones = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_equipos')
                    .select('nombre_descripcion');

                if (error) {
                    throw error;
                }

                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_descripcion)))
                    .map(nombre_descripcion => ({
                        label: nombre_descripcion,
                        value: nombre_descripcion
                    }));

                setDescripciones(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener las descripciones:', error.message);
            }
        };

        const fetchMarcas = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_marcas')
                    .select('nombre_marca');

                if (error) {
                    throw error;
                }

                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_marca)))
                    .map(nombre_marca => ({
                        label: nombre_marca,
                        value: nombre_marca
                    }));

                setMarcas(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener las marcas:', error.message);
            }
        };

        const fetchModelos = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_modelos')
                    .select('nombre_modelo');

                if (error) {
                    throw error;
                }

                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_modelo)))
                    .map(nombre_modelo => ({
                        label: nombre_modelo,
                        value: nombre_modelo
                    }));

                setModelos(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener los modelos:', error.message);
            }
        };

        const fetchUbicaciones = async () => {
            try {
                const { data, error } = await supabase
                    .from('cat_ubicaciones')
                    .select('nombre_ubicacion');

                if (error) {
                    throw error;
                }

                const uniqueOptions = Array.from(new Set(data.map(item => item.nombre_ubicacion)))
                    .map(nombre_ubicacion => ({
                        label: nombre_ubicacion,
                        value: nombre_ubicacion
                    }));

                setUbicaciones(uniqueOptions);
            } catch (error) {
                console.error('Error al obtener las ubicaciones:', error.message);
            }
        };

        fetchNumeroserie();
        fetchNumeroinventario();
        fetchDescripciones();
        fetchMarcas();
        fetchModelos();
        fetchClasificaciones();
        fetchUbicaciones();
    }, []);

    return (
        <div style={{ marginBottom: '10px' }}>
            <Group style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                <Select
                    label="Ubicación"
                    placeholder="Ubicación"
                    data={ubicaciones}
                    value={filters.nombre_ubicacion}
                    onChange={(value) => setFilters({ ...filters, nombre_ubicacion: value })}
                    name="nombre_ubicacion"
                    style={{ width: '100%', maxWidth: '150px' }}
                />
                <Select
                    label="Clasificación"
                    placeholder="Clasificacion"
                    data={clasificaciones}
                    value={filters.nombre_clasificacion}
                    onChange={(value) => setFilters({ ...filters, nombre_clasificacion: value })}
                    name="nombre_clasificacion"
                    style={{ width: '100%', maxWidth: '150px' }}
                />
                <Autocomplete
                    label="Número de serie"
                    placeholder="No. serie"
                    data={numeroserie}
                    value={filters.numero_serie}
                    onChange={(value) => setFilters({ ...filters, numero_serie: value })}
                    name="numero_serie"
                    style={{ width: '100%', maxWidth: '150px' }}
                />

                <Autocomplete
                    label="Número de inventario"
                    placeholder="No. inventario"
                    data={numeroinventario}
                    value={filters.numero_inventario}
                    onChange={(value) => setFilters({ ...filters, numero_inventario: value })}
                    name="numero_inventario"
                    style={{ width: '100%', maxWidth: '150px' }}
                />

                <Autocomplete
                    label="Descripción equipo"
                    placeholder="Equipo"
                    data={descripciones}
                    value={filters.nombre_descripcion}
                    onChange={(value) => setFilters({ ...filters, nombre_descripcion: value })}
                    name="nombre_descripcion"
                    style={{ width: '100%', maxWidth: '150px' }}
                />

                <Autocomplete
                    label="Marca"
                    placeholder="Marca"
                    data={marcas}
                    value={filters.nombre_marca}
                    onChange={(value) => setFilters({ ...filters, nombre_marca: value })}
                    name="nombre_marca"
                    style={{ width: '100%', maxWidth: '150px' }}
                />

                <Autocomplete
                    label="Modelo"
                    placeholder="Modelo"
                    data={modelos}
                    value={filters.nombre_modelo}
                    onChange={(value) => setFilters({ ...filters, nombre_modelo: value })}
                    name="nombre_modelo"
                    style={{ width: '100%', maxWidth: '150px' }}
                />
            </Group>
        </div>
    );
}

export default EquiposFiltros;
