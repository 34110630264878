import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";
import IngenierosTable from '../components/IngenierosTable';
import IngenierosForm from '../components/IngenierosForm';
import { Group, Button, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";

function CatalogoIngenieros({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [ingenieros, setIngenieros] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroIngeniero, setFiltroIngeniero] = useState('');

    useEffect(() => {
        try {
            const getIngenieros = async () => {
                dispatch(ShowLoading());
                const allIngenieros = await fetchIngenieros();
                setIngenieros(allIngenieros);
                dispatch(HideLoading());
            }
            getIngenieros();
        } catch (error) {
            dispatch(HideLoading());
        }
    }, [dispatch]);

    const fetchIngenieros = async () => {
        try {
            let { data: ingenieros, error } = await supabase
                .from('cat_ingenieros')
                .select(`
                    id_ingeniero,
                    nombre_ingeniero
                `)
                .order('id_ingeniero', { ascending: true });

            if (error) {
                throw error;
            }
            return ingenieros;
        } catch (error) {
            throw error;
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: ingenierosFiltrados, error } = await supabase
                .from('cat_ingenieros')
                .select(`
                    id_ingeniero,
                    nombre_ingeniero
                `)
                .ilike('nombre_ingeniero', `%${filtroIngeniero.toLowerCase()}%`)
                .order('id_ingeniero', { ascending: true });

            if (error) {
                throw error;
            }
            setIngenieros(ingenierosFiltrados);
        } catch (error) {
            showNotification({
                title: 'Error al filtrar ingenieros',
                message: error.message,
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };

    const getData = async () => {
        try {
            const allIngenieros = await fetchIngenieros();
            setIngenieros(allIngenieros);
        } catch (error) {
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };

    const canAddIngeniero = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_ingenieros &&
        permisos.catalogos.modulos.cat_ingenieros.acciones &&
        permisos.catalogos.modulos.cat_ingenieros.acciones.registrar_ingeniero === true;

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl">
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel">
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar ingeniero"
                                value={filtroIngeniero}
                                onChange={(event) => setFiltroIngeniero(event.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddIngeniero && (
                                <Button
                                    className='boton-gris'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}
                                >
                                    + Agregar Ingeniero
                                </Button>
                            )}
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Ingeniero" : "Editar Ingeniero"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <IngenierosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction}
                    />
                </Modal>
                <div style={{ overflowX: "auto" }}>
                    <IngenierosTable
                        ingenieros={ingenieros}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>
    );
}

export default CatalogoIngenieros;
