import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Button, Group } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";

function IngenierosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    // Valores iniciales
    const [ingeniero, setIngeniero] = useState({
        nombre_ingeniero: "",
        id_ingeniero: "" // Inicializar id_ingeniero como una cadena vacía
    });

    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setIngeniero({
                nombre_ingeniero: transactionData.nombre_ingeniero,
                id_ingeniero: transactionData.id_ingeniero
            });
        }
    }, [formMode, transactionData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setIngeniero(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateIngeniero = async () => {
        try {
            await supabase
                .from('cat_ingenieros')
                .update({
                    nombre_ingeniero: ingeniero.nombre_ingeniero
                })
                .eq('id_ingeniero', ingeniero.id_ingeniero);

            showNotification({
                title: 'Ingeniero actualizado',
                message: 'El Ingeniero se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar el Ingeniero:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el Ingeniero.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (formMode === 'add') {
                const { error } = await supabase
                    .from('cat_ingenieros')
                    .insert({
                        nombre_ingeniero: ingeniero.nombre_ingeniero
                    });

                if (error) {
                    throw error;
                }

                showNotification({
                    title: 'Ingeniero agregado',
                    message: 'El Ingeniero ha sido agregado con éxito.',
                    color: 'green',
                });
            } else if (formMode === 'edit') {
                await handleUpdateIngeniero();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al agregar/actualizar Ingeniero:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el Ingeniero.',
                color: 'red',
            });
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action="" onSubmit={handleSubmit}>
                <Group>
                    <Stack>
                        <TextInput
                            disabled
                            name="id_ingeniero"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={ingeniero.id_ingeniero}
                        />

                        <TextInput
                            name="nombre_ingeniero"
                            label="Nombre Ingeniero"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={ingeniero.nombre_ingeniero}
                            placeholder='Nombre Ingeniero'
                        />
                    </Stack>
                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Ingeniero" : "Editar Ingeniero"}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default IngenierosForm;
