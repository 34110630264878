import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Button, Group } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";

function MarcasForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    // Valores iniciales
    const [marca, setMarca] = useState({
        nombre_marca: "",
        id_marca: "" // Inicializar id_marca como una cadena vacía
    });

    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setMarca({
                nombre_marca: transactionData.nombre_marca,
                id_marca: transactionData.id_marca
            });
        }
    }, [formMode, transactionData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setMarca(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateMarca = async () => {
        try {
            await supabase
                .from('cat_marcas')
                .update({
                    nombre_marca: marca.nombre_marca
                })
                .eq('id_marca', marca.id_marca);

            showNotification({
                title: 'Marca actualizada',
                message: 'La Marca se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar la Marca:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la Marca.',
                color: 'red',
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (formMode === 'add') {
                const { error } = await supabase
                    .from('cat_marcas')
                    .insert({
                        nombre_marca: marca.nombre_marca
                    });

                if (error) {
                    throw error;
                }

                showNotification({
                    title: 'Marca agregada',
                    message: 'La Marca ha sido agregada con éxito.',
                    color: 'green',
                });
            } else if (formMode === 'edit') {
                await handleUpdateMarca();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al agregar/actualizar Marca:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la Marca.',
                color: 'red',
            });
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action="" onSubmit={handleSubmit}>
                <Group>
                    <Stack>
                        <TextInput
                            disabled
                            name="id_marca"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={marca.id_marca}
                        />

                        <TextInput
                            name="nombre_marca"
                            label="Nombre Marca"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={marca.nombre_marca}
                            placeholder='Nombre Marca'
                        />
                    </Stack>
                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Marca" : "Editar Marca"}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default MarcasForm;
